
/**
 * Bootstrap 4 imports
 * --------------------
 * to minimize bootstrap imports and remove bootstrap style components
 * following imports will only includes utility related class names
 */

@import '~bootstrap/scss/_functions.scss';
// @import '~bootstrap/scss/_variables.scss';
// need to override few variables
@import './_bootstrap-variables.scss';

// @import 'bootstrap-variables';
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/_root.scss";
@import "~bootstrap/scss/_reboot.scss";
@import "~bootstrap/scss/_type.scss";
@import "~bootstrap/scss/_images.scss";
@import "~bootstrap/scss/_code.scss";
@import "~bootstrap/scss/_grid.scss";

/**
 * Override bootstrap table variables
 */
$table-border-width: 0.5px;
$table-accent-bg: rgba(0, 0, 0, 0.02);
@import "~bootstrap/scss/_tables.scss";
/**
 * Override bootstrap table styles
 */
.table {
  th {
    border-top: none;
  }
}

$input-font-size: 14px;
@import "~bootstrap/scss/_forms.scss";
@import "~bootstrap/scss/_buttons.scss";

// when bootstrap btn-outline-* is class is used with .border-dark
// the text color should match border color
.border-dark {
  &[class*="btn-outline-"] {
    color: $yiq-text-dark;
    &:hover {
      color: $yiq-text-light;
    }
  }
}


@import "~bootstrap/scss/_transitions.scss";
@import "~bootstrap/scss/_dropdown.scss";
@import "~bootstrap/scss/_button-group.scss";
@import "~bootstrap/scss/_input-group.scss";
@import "~bootstrap/scss/_custom-forms.scss";
@import "~bootstrap/scss/_nav.scss";
@import "~bootstrap/scss/_navbar.scss";
@import "~bootstrap/scss/_card.scss";
@import "~bootstrap/scss/_breadcrumb.scss";
@import "~bootstrap/scss/_pagination.scss";
@import "~bootstrap/scss/_badge.scss";
@import "~bootstrap/scss/_jumbotron.scss";
@import "~bootstrap/scss/_alert.scss";
@import "~bootstrap/scss/_progress.scss";
@import "~bootstrap/scss/_media.scss";
@import "~bootstrap/scss/_list-group.scss";
@import "~bootstrap/scss/_close.scss";
@import "~bootstrap/scss/_toasts.scss";
@import "~bootstrap/scss/_modal.scss";
@import "~bootstrap/scss/_tooltip.scss";
@import "~bootstrap/scss/_popover.scss";
@import "~bootstrap/scss/_carousel.scss";
@import "~bootstrap/scss/_spinners.scss";
@import "~bootstrap/scss/_utilities.scss";
@import "~bootstrap/scss/_print.scss";





// /**
//  * Bootstrap 4 override
//  * --------------------
//  */
// This import increased size of output

//  @import './bootstrap.grid';


 // Since Material style uses ripple and has
 // its own styles when button element is focused
 // we will override what is set from '~bootstrap/scss/_reboot.scss'
 button:focus {
   outline: none;
 }


.input-group {
  button {
    height: 35px;
  }
}
