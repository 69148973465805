// here we are importing local bootstrap scss
@import './bootstrap';
// At last we will use our app specific styles
// It is important to place this at last
// so that we can overwrite styles added from imports above
@import './app-style';

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

// body {
//   font-size: .875rem;
//   }
  
//   .feather {
// 	width: 16px;
// 	height: 16px;
// 	vertical-align: text-bottom;
//   }
  
//   /*
//    * Sidebar
//    */
  
//   .sidebar {
// 	position: fixed;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	z-index: 100; /* Behind the navbar */
// 	padding: 0;
// 	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
//   }
  
//   .sidebar-sticky {
// 	position: -webkit-sticky;
// 	position: sticky;
// 	top: 48px; /* Height of navbar */
// 	height: calc(100vh - 48px);
// 	padding-top: .5rem;
// 	overflow-x: hidden;
// 	overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
//   }
  
//   .sidebar .nav-link {
// 	font-weight: 500;
// 	color: #333;
//   }
  
//   .sidebar .nav-link .feather {
// 	margin-right: 4px;
// 	color: #999;
//   }
  
//   .sidebar .nav-link.active {
// 	color: #007bff;
//   }
  
//   .sidebar .nav-link:hover .feather,
//   .sidebar .nav-link.active .feather {
// 	color: inherit;
//   }
  
//   .sidebar-heading {
// 	font-size: .75rem;
// 	text-transform: uppercase;
//   }
  
//   /*
//    * Navbar
//    */
  
//   .navbar-brand {
// 	padding-top: .75rem;
// 	padding-bottom: .75rem;
// 	font-size: 1rem;
// 	background-color: rgba(0, 0, 0, .25);
// 	box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
//   }
  
//   .navbar .form-control {
// 	padding: .75rem 1rem;
// 	border-width: 0;
// 	border-radius: 0;
//   }
  
//   .form-control-dark {
// 	color: #fff;
// 	background-color: rgba(255, 255, 255, .1);
// 	border-color: rgba(255, 255, 255, .1);
//   }
  
//   .form-control-dark:focus {
// 	border-color: transparent;
// 	box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
//   }
  
  /*
   * Utilities
   */
  
  .border-top { border-top: 1px solid #e5e5e5; }
  .border-bottom { border-bottom: 1px solid #e5e5e5; }


//   /*
//    * List Classes Component
//    */

// .status-active {
// 	color:rgb(28, 90, 28);
// }
// .status-unhealthy, .status-stopped {
// 	color: rgb(117, 28, 28);
// }


  /*
   * Amplify
   */
:root {
	--amplify-primary-color: #343a40;
	--amplify-primary-tint: #242a30;
	--amplify-primary-shade: #27292c;
  }

/*
* misc
*/

div.bold {
  font-weight: bold;
}

// input:read-only, textbox:read-only {
//   background-color: #bcceb9
// }

input.input-number {
  text-align: center;
  border-color: #6c757d;
}

hr {
  border-top: 1px solid #000;
  clear:both;
  display:block;
  width: 96%;
  height: 1px;
}
